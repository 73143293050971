const _findNode = (text, root) => {
  if (text) {
    let result = [];
    root.forEach((item) => {
      if (item.text.toLowerCase().indexOf(text.toLowerCase()) > -1) {
        result.push(item);
      }
      if (item.children) {
        let _d = _findNode(text, item.children);
        if (_d) _d.forEach((e) => result.push(e));
      }
    });
    return result;
  }
  return root;
};

export default _findNode;
