import axios from "@/plugins/axios";
import {
  parse_tech_group_to_create,
  parser_tech_group_to_update,
} from "@/services/references/techGroup/parsers";

/**
 * Получение дерева CUGroup
 * @return {Promise<cUGroupObject[]>}
 */
export const API_tech_group_list = () => {
  return axios("/cUGroup", {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Получение CUGroup по ID
 * @param {number} groupId
 * @return {Promise<cUGroupObject>}
 */
export const API_tech_group_view = (groupId) => {
  return axios(`/cUGroup/${groupId}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Обновление CUGroup
 * @param {number} groupId
 * @param {Object} value
 * @return {Promise<cUGroupObject>}
 */
export const API_tech_group_update = (groupId, value) => {
  return axios(`/cUGroup/${groupId}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: parser_tech_group_to_update(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание CUGroup
 * @param {Object} value
 * @return {Promise<cUGroupObject>}
 */
export const API_tech_group_create = (value) => {
  return axios("/cUGroup/", {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_tech_group_to_create(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Удаление CUGroup
 * @param {number} groupId
 * @return {Promise<boolean>}
 */
export const API_tech_group_delete = (groupId) => {
  return axios(`/cUGroup/${groupId}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};
