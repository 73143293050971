<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Группы технологических карт</h2>
      <div>
        <UIButton
          class="add-btn"
          text="Добавить"
          leftIcon="bi-plus"
          @click="showAddTech"
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <MapTree :group-id="currentGroup" />
      </div>
      <div class="col sticky-top">
        <MapTable :group-id="currentGroup" />
      </div>
    </div>
  </div>
</template>

<script>
import MapTable from "@/views/References/technological-maps/components/mapTable";
import MapTree from "@/views/References/technological-maps/components/mapTree";
import UIButton from "@/components/UI/Button";

export default {
  name: "technological-maps",
  components: { UIButton, MapTable, MapTree },
  computed: {
    currentGroup: function () {
      return this.$route.query.group;
    },
  },

  methods: {
    showTechDetails(id) {
      this.$router.push({
        path: `/references/technological-maps/${id}`,
      });
    },
    showAddTech() {
      this.$router.push({
        path: `/references/technological-maps/add`,
      });
    },
  },
};
</script>
