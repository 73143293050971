<template>
  <ul class="ps-0 mt-2 row py-0" v-show="treeData">
    <tree-item
      v-for="(item, itemId) in treeData"
      :key="itemId"
      :item="item"
      :selectId="selectId"
      @select-item="selectItem"
    ></tree-item>
  </ul>
</template>

<script>
import TreeItem from "@/components/TreeView/TreeItem";

export default {
  name: "TreeView",
  components: { TreeItem },
  props: {
    treeData: Array,
    editable: {
      type: Boolean,
      default: false,
    },
    selectById: {
      type: [Number, String],
      default: -1,
    },
  },
  data() {
    return {
      selectId: -1,
    };
  },
  watch: {
    selectById: function (value) {
      this.selectId = value;
    },
  },
  methods: {
    selectItem: function (item) {
      this.$emit("select-item", item);
      this.selectId = item.id;
    },
  },
};
</script>

<style scoped></style>
