<template>
  <button
    :class="['ui-btn remove']"
    :title="text"
    @click="clEvent"
    :disabled="!!disabled"
    :type="type"
  >
    <i v-if="leftIcon" :class="leftIcon"></i>
    <span v-if="text && !submitState">{{ text }}</span>
    <span v-if="submitState">Вы уверены?</span>
    <i v-if="rightIcon" :class="rightIcon"></i>
    <i
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    ></i>
  </button>
</template>

<script>
export default {
  name: "RemoveUIButton",
  props: {
    leftIcon: String,
    rightIcon: String,
    type: {
      type: String,
      default: "button",
    },
    text: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      submitState: false,
    };
  },
  methods: {
    setStatus(s) {
      this.isLoading = s;
    },
    clEvent() {
      if (this.submitState) {
        this.$emit("click");
      } else {
        setTimeout(() => (this.submitState = false), 2500);
      }
      this.submitState = !this.submitState;
    },
  },
};
</script>

<style scoped></style>
