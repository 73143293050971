export let createTechGroupSchema = {
  parent_c_u_group_id: 0,
  WorkIdentifiedObject: {
    IdentifiedObject: {
      name: "",
      description: "",
      enproCode: "",
      names: [],
    },
  },
};

export let updateTechGroupSchema = {
  id: 0,
  parent_c_u_group_id: 0,
  WorkIdentifiedObject: {
    IdentifiedObject: {
      name: "",
      description: "",
      enproCode: "",
      names: [],
    },
  },
};
