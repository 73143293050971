import axios from "@/plugins/axios";
import Vue from "vue";
/*
Тех карты
 */
/**
 * @typedef {object} TechMapCatalogServer
 * @property {number} id
 * @property {object} parentCUGroup
 * @property {object} WorkIdentifiedObject
 */
/**
 * @typedef {object} TechMapCatalogUI
 * @property {number} parentId
 * @property {number} id
 * @property {string} parentName
 * @property {string} name
 * @property {string} code
 */
/*
Тех нормы категории
 */
//GET Получение данных по id
export const API_TreeMapsCompatibleUnit = (id) => {
  return axios(`/compatibleUnit?cUGroupId=${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      let parseValues = [];
      response.data.data.forEach((row) => {
        parseValues.push(parseTechMap(row));
      });
      return parseValues;
    })
    .catch((error) => {
      Vue.notify({
        type: "error",
        text: "Не удалось получить тех. карты",
      });
      throw error;
    });
};

function parseTechMap(row) {
  const modelData = {
    id: row.id,
    name: row.WorkDocument.Document.IdentifiedObject.name,
    description: row.WorkDocument.Document.subject,
    code: row.WorkDocument.Document.IdentifiedObject.enproCode,
    type: row.WorkDocument.Document.type,
    allowedActionId: row.CUAllowableAction?.id,
    statusId: row.WorkDocument.Document.status?.id,
    typesOfPropertyId: row.PropertyUnit?.id,
    group: {
      id: row.CUGroup.id,
      text: row.CUGroup.WorkIdentifiedObject.IdentifiedObject.name,
    },
    quantity: row.quantity,
    costTypeId: row.CostType?.id,
    expanded: row.estCost?.value,
  };
  let laborItems = [];
  row.CULaborItems.forEach((element) => {
    laborItems.push({
      labor: element.CULaborCode?.WorkIdentifiedObject?.IdentifiedObject?.name,
      qualification:
        element.QualificationRequirement?.WorkIdentifiedObject?.IdentifiedObject
          ?.Names?.[0].name,
      code: element.CULaborCode?.code,
      laborDuration: element.laborDuration.value,
      laborUnit: "ч",
      laborRate: element.laborRate.value,
      rateUnit: "руб",
      status: element.status?.value,
    });
  });
  let workItems = [];
  row.CUWorkEquipmentItems.forEach((element) => {
    workItems.push({
      name: element.WorkIdentifiedObject.IdentifiedObject.name,
      shortName: element.WorkIdentifiedObject.IdentifiedObject.Names?.[0]?.name,
      code: element.WorkIdentifiedObject.IdentifiedObject.enproCode,
      workDuration: element.enproDuration?.value,
      workUnit: "ч",
      rate: element.rate?.value,
      rateUnit: "руб",
      status: element.status?.value,
    });
  });

  let materialItems = [];
  row.CUMaterialItems.forEach((element) => {
    materialItems.push({
      name: element.WorkIdentifiedObject.IdentifiedObject.name,
      type: element.TypeMaterial.costType,
      quantity: element.TypeMaterial.quantity,
      cost: element.TypeMaterial.unit?.value,
      materialCount: element.quantity?.value,
      stockItem: element.TypeMaterial.stockItem,
      code: element.WorkIdentifiedObject.IdentifiedObject.enproCode,
      status: element.status?.value,
    });
  });
  let otherItems = [];
  row.CUContractorItems.forEach((element) => {
    otherItems.push({
      name: element?.WorkIdentifiedObject.IdentifiedObject.name,
      code: element?.activityCode,
      rate: element?.bidAmount?.value,
      status: element?.status,
    });
  });

  return {
    modelData: modelData,
    laborItems: laborItems,
    workItems: workItems,
    materialItems: materialItems,
    otherItems: otherItems,
  };
}
