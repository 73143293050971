<template>
  <div class="box sticky-top">
    <TableSlot v-bind="techMaps" actions>
      <template v-slot:actions="{ row }">
        <UIButton text="Открыть" @click="showTechDetails(row.modelData.id)" />
      </template>
    </TableSlot>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import TableSlot from "@/components/TableSlot";
import { API_TreeMapsCompatibleUnit } from "@/services/techMap";

export default {
  name: "mapTable",
  components: {
    UIButton,
    TableSlot,
  },
  props: {
    groupId: {
      type: [Number, String, null],
    },
  },
  data() {
    return {
      techMaps: {
        columns: [
          {
            name: "№",
            path: "modelData.id",
          },
          {
            name: "Наименование",
            path: "modelData.name",
          },
          {
            name: "Шифр ТК",
            path: "modelData.code",
          },
          {
            name: "Базовая единица",
            path: "modelData.quantity",
          },
        ],
        data: [],
        filters: {
          "modelData.name": "",
        },
      },
    };
  },
  mounted() {
    if (this.groupId)
      API_TreeMapsCompatibleUnit(this.groupId).then(
        (res) => (this.techMaps.data = res)
      );
  },
  watch: {
    groupId: async function (value) {
      if (value != null)
        this.techMaps.data = await API_TreeMapsCompatibleUnit(value);
    },
  },
  methods: {
    showTechDetails(id) {
      this.$router.push({
        path: `/references/technological-maps/${id}`,
      });
    },
  },
};
</script>

<style scoped></style>
