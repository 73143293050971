<template>
  <div>
    <UIButton
      @click="modal.toggle()"
      :text="buttonText"
      :leftIcon="buttonLeftIcon"
      :disabled="buttonDisabled"
      float
    />

    <!-- Modal -->
    <form
      @submit.prevent="
        $emit('submit');
        modal.hide();
      "
    >
      <div class="modal fade" :id="id" :ref="id">
        <div
          :class="[
            'modal-dialog modal-dialog-centered modal-dialog-scrollable',
            'modal-' + size,
          ]"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ title }}</h5>
            </div>

            <div class="modal-body">
              <slot> Тело окна </slot>
            </div>
            <div class="modal-footer">
              <UIButton text="Закрыть" data-bs-dismiss="modal" float />
              <slot name="actions"></slot>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import { Modal } from "bootstrap";
import { generateUUID } from "@/plugins/uuidGenerator";

export default {
  name: "ModalComponent",
  components: { UIButton },
  props: {
    title: String,
    size: {
      type: String,
      default: "xl",
    },
    buttonDisabled: Boolean,
    buttonText: String,
    buttonLeftIcon: String,
  },
  emits: ["submit"],
  data() {
    return {
      modal: null,
      id: "modalComponent-" + generateUUID(),
    };
  },
  mounted() {
    this.modal = new Modal(this.$refs[this.id]);
    this.modal.hide();
  },
};
</script>

<style scoped></style>
