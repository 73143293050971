<template>
  <li class="tree py-0">
    <div
      :class="{ bold: isFolder, selected: selectId === item.id }"
      @click="toggle"
      class="ps-2 tree-node-label"
    >
      <span v-if="isFolder">
        <i :class="isOpen ? 'bi bi-folder2-open' : 'bi bi-folder2'"></i>
      </span>
      <span v-else><i class="bi bi-journal"></i></span>
      {{ item.text }}
      <span v-if="isFolder">[{{ isOpen ? "-" : "+" }}]</span>
    </div>
    <ul class="ps-3 row overflow-hidden" v-show="isOpen" v-if="isFolder">
      <tree-item
        class="item"
        v-for="(child, index) in item.children"
        :key="index"
        :item="child"
        :selectId="selectId"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
        @select-item="$emit('select-item', $event)"
      ></tree-item>
    </ul>
  </li>
</template>

<script>
export default {
  name: "TreeItem",
  props: {
    item: Object,
    selectId: {
      type: [Number, String],
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    isFolder: function () {
      return this.item.children && this.item.children.length;
    },
  },
  methods: {
    toggle: function () {
      if (this.isFolder) {
        this.isOpen = !this.isOpen;
      }
      this.$emit("select-item", this.item);
    },
  },
  mounted() {
    function _findNode(id, root) {
      if (!id) return false;
      if (root.id === id) {
        return true;
      }
      if (root.children) {
        return root.children.some((i) => _findNode(id, i));
      }
      return false;
    }
    if (this.selectId) {
      this.isOpen = _findNode(this.selectId, this.item);
    }
  },
};
</script>

<style lang="scss">
.tree-node-label {
  //color: $text-main;
  cursor: pointer;
}
.tree-node-label:hover {
  background-color: $highlight-hover;
  color: $highlight-text;
  border-radius: $border-radius;
}
.tree {
  list-style-type: none;
  //margin-left: 1.5rem;

  //&:first-child {
  //  margin-left: 0;
  //}

  .selected {
    background-color: $highlight;
    color: #18191d;
    border-radius: $border-radius;
  }
}
</style>
