<template>
  <div>
    <div class="d-inline-flex gap-2">
      <Modal
        title="Добавление каталога в группы тех карт"
        button-text="Добавить группу"
        button-left-icon="bi-plus"
        size="lg"
        @submit="createGroupTech"
      >
        <template v-slot:default>
          <div class="row gap-1">
            <dl class="col">
              <div class="row gap-2">
                <div>
                  <dt class="col">Наименование:</dt>
                  <dl class="col">
                    <EditableInput
                      v-model="createGroupForm.name"
                      editable
                      required
                    />
                  </dl>
                </div>
                <div>
                  <dt class="col">Родительская группа:</dt>
                  <dl class="col mb-0">
                    <EditableInput
                      v-model="createGroupForm.parentName"
                      required
                    />
                  </dl>
                </div>
              </div>
            </dl>
            <dl class="col">
              <div class="row gap-2">
                <div>
                  <dt class="col">Шифр тех карты:</dt>
                  <dl class="col mb-0">
                    <EditableInput
                      v-model="createGroupForm.code"
                      editable
                      required
                    />
                  </dl>
                </div>
              </div>
            </dl>
            <div class="col-12">
              <TreeView
                class=""
                :treeData="categoriesTree.data"
                @select-item="selectCategoryTech"
              />
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <UIButton text="Добавить" type="submit" />
        </template>
      </Modal>

      <Modal
        title="Редактирование"
        button-text="Редактировать"
        button-left-icon="bi-pencil"
        :button-disabled="!selectedNode"
        size="xl"
        @submit="updateGroupTech"
      >
        <template v-slot:default>
          <div class="row gap-1" v-if="selectedNode">
            <dl class="col">
              <div class="row gap-2">
                <div>
                  <dt class="col">Наименование:</dt>
                  <dl class="col">
                    <EditableInput
                      v-model="
                        selectedNode.WorkIdentifiedObject.IdentifiedObject.name
                      "
                      editable
                      required
                    />
                  </dl>
                </div>

                <div>
                  <dt class="col">Родительская группа:</dt>
                  <dl class="col mb-0">
                    <EditableInput
                      :value="
                        lodash.get(
                          selectedNode,
                          'parentCUGroup.WorkIdentifiedObject.IdentifiedObject.name'
                        )
                      "
                      required
                    />
                  </dl>
                </div>
              </div>
            </dl>
            <dl class="col">
              <div class="row gap-2">
                <div>
                  <dt class="col">Шифр тех карты:</dt>
                  <dl class="col mb-0">
                    <EditableInput
                      v-model="
                        selectedNode.WorkIdentifiedObject.IdentifiedObject
                          .enproCode
                      "
                      editable
                      required
                    />
                  </dl>
                </div>
              </div>
            </dl>
            <div class="col-12">
              <TreeView
                class=""
                :treeData="categoriesTree.data"
                @select-item="
                  (e) => {
                    lodash.set(
                      selectedNode,
                      'parentCUGroup.WorkIdentifiedObject.IdentifiedObject.name',
                      e.text
                    );
                    lodash.set(selectedNode, 'parentCUGroup.id', e.id);
                  }
                "
              />
            </div>
          </div>
        </template>
        <template v-slot:actions>
          <UIButton text="Сохранить" type="submit" />
          <RemoveButton
            text="Удалить"
            left-icon="bi-trash"
            @click="deleteGroupTech"
          />
        </template>
      </Modal>
    </div>
    <EditableInput
      class="my-2"
      v-model="search"
      placeholder="Поиск группы по названию"
      editable
      required
    />
    <TreeView
      :treeData="categoriesFind"
      :selectById="categoriesTree.selectedNodeId"
      @select-item="selectNodeEvent"
    ></TreeView>
  </div>
</template>

<script>
import UIButton from "@/components/UI/Button";
import RemoveButton from "@/components/UI/RemoveButton";
import EditableInput from "@/components/UI/EditableInput";

import TreeView from "@/components/TreeView/TreeView";

import Modal from "@/components/UI/Modal";
import {
  API_tech_group_create,
  API_tech_group_delete,
  API_tech_group_list,
  API_tech_group_update,
} from "@/services/references/techGroup";
import findNode from "@/plugins/findNode";

export default {
  name: "mapTree",
  components: {
    UIButton,
    RemoveButton,
    EditableInput,
    TreeView,
    Modal,
  },
  props: {
    groupId: {
      type: [Number, String, null],
    },
  },
  data() {
    return {
      categoriesTree: {
        selectedNodeId: null,
        selectedNode: {
          text: "",
        },
        /**
         * @type cUGroupObject[]
         */
        data: [],
      },
      /**
       * @type {GroupObject || null}
       */
      selectedNode: null,
      createGroupForm: {
        name: null,
        code: null,
        parentId: null,
        parentName: null,
      },
      search: "",
    };
  },
  watch: {
    groupId: async function (value) {
      this.categoriesTree.selectedNodeId = value;
    },
  },
  mounted() {
    this.loadTechGroupList();
    if (this.groupId) this.categoriesTree.selectedNodeId = this.groupId;
  },
  computed: {
    categoriesFind: function () {
      return findNode(this.search, this.categoriesTree.data);
    },
  },
  methods: {
    loadTechGroupList() {
      API_tech_group_list().then((res) => {
        this.categoriesTree.data = res;
      });
    },
    selectNodeEvent(node) {
      this.categoriesTree.selectedNodeId = node.id;
      this.categoriesTree.selectedNode = node;
      if (!node.children) {
        this.$router.push({ query: { group: node.id } });
      }
    },
    selectTechMapEvent(item) {
      this.$emit("data-select", item.modelData.id);
      this.$emit("model-select", item.modelData);
    },
    selectCategoryTech(node) {
      this.createGroupForm.parentId = node.id;
      this.createGroupForm.parentName = node.text;
    },
    createGroupTech() {
      API_tech_group_create(this.createGroupForm).then(() => {
        this.loadTechGroupList();
        Object.keys(this.createGroupForm).forEach((key) => {
          this.createGroupForm[key] = null;
        });
      });
    },
    updateGroupTech() {
      API_tech_group_update(this.selectedNode.id, this.selectedNode).then(() =>
        this.loadTechGroupList()
      );
    },
    deleteGroupTech() {
      API_tech_group_delete(this.selectedNode.id).finally(() =>
        window.location.reload()
      );
    },
  },
};
</script>

<style scoped></style>
