import { createTechGroupSchema } from "@/services/references/techGroup/schemas";

export const parse_tech_group_to_create = (value) => {
  let data = Object.assign({}, createTechGroupSchema);
  data.WorkIdentifiedObject.IdentifiedObject.name = value.name;
  data.WorkIdentifiedObject.IdentifiedObject.enproCode = value.code;
  data.parent_c_u_group_id = value.parentId;
  return data;
};

export const parser_tech_group_to_update = (value) => {
  let data = Object.assign({}, value);
  data.parent_c_u_group_id = value.parentCUGroup.id;
  return data;
};
